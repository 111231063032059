


import {Options, Vue} from "vue-class-component";
import SensorHeaderComponent from "@/components/SensorHeaderComponent.vue";
import SensorLiveDataChartComponent from "@/components/SensorLiveDataChartComponent.vue";
import SensorHistoryComponent from "@/components/SensorHistoryComponent.vue";
import SensorDetailsComponent from "@/components/SensorDetailsComponent.vue";

@Options({
    components: {SensorDetailsComponent, SensorHistoryComponent, SensorLiveDataChartComponent, SensorHeaderComponent}
})
export default class SensorView extends Vue {
    private close() {
        console.log('close requested')
        this.$router.push('/');
    }
}
