import {SensorsService} from "@/services/SensorsService";
import {MeasureModel} from "@/models/MeasureModel";
import {SensorModel} from "@/models/SensorModel";
import {SaveSensorConfigurationRequestModel} from "@/models/SaveSensorConfigurationRequestModel";
import { faker } from '@faker-js/faker';


export class SensorsServiceDemoImpl implements SensorsService {

    private readonly sensor : SensorModel = {
        name : "Demo",
        sn : "20240917",
        appName : 'Client app',
        appVersion : 'v1.0.0',
        lastActivityDate : new Date(),
        lastCarDetectState : false,
        configuration : {
            confirmTimeout : 100,
            detectLevel : 50,
            filterGain : 10,
            lowPowerTOff : 5,
            measurePeriod : 20,
            noDetectLevel : 1,
            slopeDetect : 10,
            stableLevel : 50
        }
    }

    calibrate(sn: string): Promise<void> {
        return Promise.resolve(undefined);
    }

    getLastMeasure(sn: string): Promise<MeasureModel> {
        return Promise.resolve(this.generateRandomSensorData());
    }



    getMeasuresFromSensor(sn: string): Promise<MeasureModel[]> {
        return Promise.resolve([this.generateRandomSensorData(),this.generateRandomSensorData()]);
    }

    getMeasuresFromSensorSinceDate(sn: string, date: Date): Promise<MeasureModel[]> {
        const number = (new Date().getTime() - date.getTime()) / 100;
        const res = [];
        for (let i = 0; i < number; i++) {
            res.push(this.generateRandomSensorData());
        }
        res.sort((a, b) => a.date.getTime() - b.date.getTime() );
        return Promise.resolve(res);
    }

    getMeasuresFromSensorWithIntervalDate(sn: string, from: Date, to: Date): Promise<MeasureModel[]> {
        const number = (to.getTime() - from.getTime()) / (1000 * 60 * 60);
        const res = [];
        for (let i = 0; i < number; i++) {
            res.push(this.generateRandomSensorData());
        }
        res.sort((a, b) => a.date.getTime() - b.date.getTime() );
        return Promise.resolve(res);
    }

    getOneSensor(sn: string): Promise<SensorModel> {
        return Promise.resolve(this.sensor);
    }

    getSensors(): Promise<SensorModel[]> {
        return Promise.resolve([this.sensor]);
    }

    login(password: string): Promise<string> {
        return Promise.resolve("");
    }

    saveSensorConfiguration(sn: string, configuration: SaveSensorConfigurationRequestModel): Promise<void> {
        return Promise.resolve();
    }

    setName(sn: string, name: string): Promise<void> {
        this.sensor.name = name;
        return Promise.resolve();
    }


    private generateRandomSensorData() {
        return {
            date: faker.date.recent(),
            carDetected: faker.datatype.boolean(),
            delta: faker.number.int({max: 1000}),
            flagDetect: faker.number.int({max: 1000}),
            slopeDelta: faker.number.int({max: 1000}),
            stableDelta: faker.number.int({max: 1000}),
            state: faker.number.int({max: 10})
        };
    }

}