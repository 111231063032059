


import {Options, Vue} from "vue-class-component";
import {Context} from "@/Context";
import {SensorsService} from "@/services/SensorsService";
import Session from "@/instances/Session";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";

@Options({
    components: {ErrorDialogComponent}
})
export default class LoginView extends Vue {
    private readonly service: SensorsService = Context.provideSensorService();
    private passwordUiValue: string = '';
    private showPassword: boolean = false;

    private errorDialog = {
        title : 'Erreur',
        message : '',
        show: false
    };

    private async login(): Promise<void> {
        try {
            const token = await this.service.login(this.passwordUiValue);
            Session.setToken(token);
            this.$router.push({name: 'home'});
        } catch (e) {
            console.error(e);
            if(e instanceof Error)
                this.showError(e);
        }
    }

    private showError(e: Error) {
        this.errorDialog.message = e.message;
        this.errorDialog.show = true;
    }
}
