
import {Options, Vue} from "vue-class-component";
import * as echarts from 'echarts';
import {SensorsService} from "@/services/SensorsService";
import {Context} from "@/Context";
import {SensorModel} from "@/models/SensorModel";

@Options({
    props: {
        sensorId: String
    }
})
export default class SensorLiveDataChartComponent extends Vue {
    private readonly DEFAULT_PERIOD_MIN = 5;

    private readonly service: SensorsService = Context.provideSensorService();
    private sensorId?: string;
    private sensor?: SensorModel;
    private date: Date = new Date(new Date().getTime() - (this.DEFAULT_PERIOD_MIN * 60000));
    private intervalId?: number;

    private _selectedPeriod = this.DEFAULT_PERIOD_MIN


    set selectedPeriod(value: number) {
        this._selectedPeriod = value;
    }

    get selectedPeriod(): number {
        return this._selectedPeriod;
    }

    async mounted(): Promise<void> {
        if (this.sensorId !== undefined)
            await this.loadUi(this.sensorId!);
    }

    private async loadUi(sn: string): Promise<void> {
        let deltaSeries: (number | Date)[][] = [];
        let detectSeries: (number | Date)[][] = [];
        let stateSeries: (number | Date)[][] = [];
        let stableDelta: (number | Date)[][] = [];
        let slopeDelta: (number | Date)[][] = [];

        const chart = echarts.init(document.getElementById('live-data-chart'));

        this.sensor = await this.service.getOneSensor(this.sensorId!);

        chart.setOption({
            animation : false,
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {}
                }
            },
            tooltip: {
                trigger: 'axis',
            },
            color: [
                'rgb(182,0,0)',
                'rgb(0,3,255)',
                'rgb(20,152,0)',
                'rgb(0,157,229)',
                'rgb(243,24,239)'
            ],
            axisPointer: {
                link: [
                    {
                        xAxisIndex: 'all'
                    }
                ]
            },
            grid: [
                {
                    left: 60,
                    right: 50,
                    height: 150
                },
                {
                    left: 60,
                    right: 50,
                    top: 270,
                    height: 80
                },
                {
                    left: 60,
                    right: 50,
                    top: 400,
                    height: 80
                },
                {
                    left: 60,
                    right: 50,
                    top: 530,
                    height: 80
                },
                {
                    left: 60,
                    right: 50,
                    top: 660,
                    height: 80
                }
            ],
            xAxis: [
                {
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 1,
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 2,
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 3,
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 4,
                    type: 'time',
                    boundaryGap: false
                }],
            yAxis: [
                {
                    type: 'value',
                    name: 'Delta'
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    name: 'Detect',
                },
                {
                    gridIndex: 2,
                    type: 'value',
                    name: 'State',
                },
                {
                    gridIndex: 3,
                    type: 'value',
                    name: 'Stable delta',
                },
                {
                    gridIndex: 4,
                    type: 'value',
                    name: 'Slope delta',
                }],
            series: [
                {
                    data: deltaSeries,
                    type: 'line'
                },
                {
                    data: detectSeries,
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    symbol: 'none'
                },
                {
                    data: stateSeries,
                    type: 'line',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    symbol: 'none'
                },
                {
                    data: stableDelta,
                    type: 'line',
                    xAxisIndex: 3,
                    yAxisIndex: 3,
                    symbol: 'none'
                },
                {
                    data: slopeDelta,
                    type: 'line',
                    xAxisIndex: 4,
                    yAxisIndex: 4,
                }
            ]
        });

        this.intervalId = setInterval(async () => {
            deltaSeries = [];
            detectSeries = [];
            stateSeries = [];
            stableDelta = [];
            slopeDelta = [];

            const measures = await this.service.getMeasuresFromSensorSinceDate(sn, this.date);
            this.date.setTime(new Date().getTime() - (this.selectedPeriod * 60000));

            for (const m of measures) {
                deltaSeries.push([m.date, m.delta]);
                detectSeries.push([m.date, m.carDetected ? 1 : 0]);
                stateSeries.push([m.date, m.state]);
                stableDelta.push([m.date, m.stableDelta]);
                slopeDelta.push([m.date, m.slopeDelta]);
            }

            let shiftCounter = 0;
            for (let i = 0; i < deltaSeries.length; i++) {
                if ((deltaSeries[i][0] as Date).getTime() < this.date.getTime()) {
                    shiftCounter++;
                }
            }

            for (let i = 0; i < shiftCounter; i++) {
                deltaSeries.shift();
                detectSeries.shift();
                stateSeries.shift();
                stableDelta.shift();
                slopeDelta.shift();
            }

            chart.setOption({
                series: [
                    {
                        name: 'Delta',
                        type: 'line',
                        data: deltaSeries
                    },
                    {
                        step: 'start',
                        data: detectSeries,
                        name: 'Detect',
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 1
                    },
                    {
                        step: 'start',
                        data: stateSeries,
                        name: 'State',
                        type: 'line',
                        xAxisIndex: 2,
                        yAxisIndex: 2
                    },
                    {
                        step: 'start',
                        data: stableDelta,
                        type: 'line',
                        xAxisIndex: 3,
                        yAxisIndex: 3,
                        name: 'Stable delta'
                    },
                    {
                        data: slopeDelta,
                        type: 'line',
                        xAxisIndex: 4,
                        yAxisIndex: 4,
                        name: 'Slope delta'
                    }
                ]
            });
        }, 1000)
    }

    unmounted(): void {
        if (this.intervalId !== undefined)
            clearInterval(this.intervalId);
    }


}
