
import {Options, Vue} from "vue-class-component";
import * as echarts from 'echarts';
import {SensorsService} from "@/services/SensorsService";
import {Context} from "@/Context";
import Datepicker from "@vuepic/vue-datepicker";
import {EChartsType} from "echarts";
import {SensorModel} from "@/models/SensorModel";

@Options({
    props: {
        sensorId: String
    },
    components: {Datepicker}
})
export default class SensorHistoryComponent extends Vue {

    private readonly service: SensorsService = Context.provideSensorService();

    private sensorId?: string;
    private ui = {
        showProgress: true,
        showDateIntervalSelector: false,
    }

    private _date: Date[] = this.getTodayInterval();
    private historyChart?: EChartsType;
    private sensor? : SensorModel;

    set date(value: Date[]) {
        this._date = value;
        this.reloadChart();
    }

    get date(): Date[] {
        return this._date;
    }

    mounted(): void {
        if (this.sensorId !== undefined)
            this.loadUi();
    }

    private async loadUi() {
        this.historyChart = echarts.init(document.getElementById('history-chart'));
        this.sensor = await this.service.getOneSensor(this.sensorId!);

        this.historyChart.setOption({
            color: [
                'rgb(182,0,0)',
                'rgb(0,3,255)',
                'rgb(20,152,0)',
                'rgb(0,157,229)',
                'rgb(243,24,239)'
            ],
            tooltip: {
                trigger: 'axis'
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    saveAsImage: {}
                }
            },
            axisPointer: {
                link: [
                    {
                        xAxisIndex: 'all'
                    }
                ]
            },
            grid: [
                {
                    left: 60,
                    right: 50,
                    height: 150
                },
                {
                    left: 60,
                    right: 50,
                    top: 270,
                    height: 80
                },
                {
                    left: 60,
                    right: 50,
                    top: 400,
                    height: 80
                },
                {
                    left: 60,
                    right: 50,
                    top: 530,
                    height: 80
                },
                {
                    left: 60,
                    right: 50,
                    top: 660,
                    height: 90,
                }
            ],
            xAxis: [
                {
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 1,
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 2,
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 3,
                    type: 'time',
                    boundaryGap: false
                },
                {
                    gridIndex: 4,
                    type: 'time',
                    boundaryGap: false
                }],
            yAxis: [
                {
                    type: 'value',
                    name: 'Delta',
                    boundaryGap: ['0%', '100%']
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    name: 'Detect',
                },
                {
                    gridIndex: 2,
                    type: 'value',
                    name: 'State',
                },
                {
                    gridIndex: 3,
                    type: 'value',
                    name: 'Stable delta',
                },
                {
                    gridIndex: 4,
                    type: 'value',
                    name: 'Slope delta',
                }],
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                    xAxisIndex: [0, 1, 2, 3, 4]
                },
                {
                    start: 50,
                    end: 100,
                    xAxisIndex: [0, 1, 2, 3, 4]
                }
            ]
        })
        await this.reloadChart();
    }

    private async reloadChart() {
        let deltaSeries: (number | Date)[][] = [];
        let detectSeries: (number | Date)[][] = [];
        let stateSeries: (number | Date)[][] = [];
        let stableDelta: (number | Date)[][] = [];
        let slopeDelta: (number | Date)[][] = [];

        this.ui.showProgress = true;

        if (this.date[0] !== null && this.date[1] !== null)
            for (const m of await this.service.getMeasuresFromSensorWithIntervalDate(this.sensorId!, this.date[0], this.date[1])) {
                deltaSeries.push([m.date, m.delta]);
                detectSeries.push([m.date, m.carDetected ? 1 : 0]);
                stateSeries.push([m.date, m.state]);
                stableDelta.push([m.date, m.stableDelta]);
                slopeDelta.push([m.date, m.slopeDelta]);
            }

        this.ui.showProgress = false;

        this.historyChart!.setOption({
            series: [
                {
                    name: 'Delta',
                    type: 'line',
                    data: deltaSeries
                },
                {
                    step: 'start',
                    data: detectSeries,
                    name: 'Detect',
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    symbol: 'none'
                },
                {
                    step: 'start',
                    data: stateSeries,
                    name: 'State',
                    type: 'line',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    symbol: 'none'
                },
                {
                    step: 'start',
                    data: stableDelta,
                    type: 'line',
                    xAxisIndex: 3,
                    yAxisIndex: 3,
                    name: 'Stable delta',
                    symbol: 'none'
                },
                {
                    data: slopeDelta,
                    type: 'line',
                    xAxisIndex: 4,
                    yAxisIndex: 4,

                    name: 'Slope delta'
                }
            ]
        });
    }

    private setIntervalToDay() {
        this.date = this.getTodayInterval();
    }

    private setIntervalLastSevenDays() {
        const dates = this.getTodayInterval();
        dates[0].setDate(dates[0].getDate() - 7);

        this.date = dates;
    }

    private setIntervalYesterday() {
        const dates = this.getTodayInterval();
        dates[0].setDate(dates[0].getDate() - 1);
        dates[1].setDate(dates[1].getDate() - 1);

        this.date = dates;
    }

    private getTodayInterval(): Date[] {
        const today1 = new Date();
        const today2 = new Date();

        today1.setHours(0, 0, 0, 0)
        today2.setHours(23, 59, 59, 999)

        return [today1, today2];
    }
}
