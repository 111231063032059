import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BigHeaderComponent = _resolveComponent("BigHeaderComponent")!
  const _component_SensorsTableComponent = _resolveComponent("SensorsTableComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", null, [
      _createVNode(_component_BigHeaderComponent)
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_SensorsTableComponent)
    ])
  ]))
}