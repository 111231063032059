
import router from "@/router";
import {Vue} from "vue-class-component";

export default class NavigationComponent extends Vue {

    private goToHome() : void {
        router.push(`/`);
    }
}

