import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f82b11e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-no-wrap justify-start align-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    width: "350",
    color: "blue-lighten-5"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_avatar, {
          class: "ma-3",
          size: "50",
          rounded: "0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: this.logoUrl,
              alt: "logo"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          _createVNode(_component_v_card_title, { class: "text-h6 text-blue-grey-darken-3" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "text-h5" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.value), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}