import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/img/du-logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_navigation_drawer, null, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (this.goToHome())),
                src: _imports_0,
                alt: "Diffusion Urbaine logo"
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, {
                    "prepend-icon": "mdi-view-dashboard",
                    title: "Home",
                    value: "dashboard",
                    rounded: "xl",
                    color: "primary",
                    active: "true",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (this.goToHome()))
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}