import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-560edacc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "field-value",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.showField()))
    }, _toDisplayString(_ctx.value), 513), [
      [_vShow, !this.fieldShown]
    ]),
    _withDirectives(_createVNode(_component_v_text_field, {
      "hide-details": "",
      variant: "outlined",
      type: _ctx.type,
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
      class: "field-value form-control",
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (this.showField())),
      onKeydown: [
        _cache[3] || (_cache[3] = _withKeys(($event: any) => (this.validate()), ["enter"])),
        _cache[4] || (_cache[4] = _withKeys(($event: any) => (this.cancel()), ["esc"]))
      ]
    }, null, 8, ["type", "modelValue"]), [
      [_vShow, this.fieldShown]
    ])
  ]))
}