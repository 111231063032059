

import {Options, Vue} from "vue-class-component";
import {SensorsService} from "@/services/SensorsService";
import {Context} from "@/Context";
import {SensorConfigurationModel} from "@/models/SensorConfigurationModel";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";
import EditableLabelComponent from "@/components/common/EditableLabelComponent.vue";
import {SaveSensorConfigurationRequestModel} from "@/models/SaveSensorConfigurationRequestModel";

@Options({
    components: {
        EditableLabelComponent,
        ErrorDialogComponent
    },
    props: {
        sensorId: String
    }
})
export default class SensorDetailsComponent extends Vue {

    private readonly service: SensorsService = Context.provideSensorService();
    private sensorId?: string;

    private readonly ui = {
        disableRefreshButton: false,
        lastUpdateTime: 'Jamais',
        errorDialog: {
            show: false,
            message: ''
        }
    }

    private readonly map: Map<string, Item> = new Map<string, Item>();

    mounted(): void {
        if (this.sensorId !== undefined)
            this.loadUi().catch((e) => {
                console.error('Unable to load sensor details component.', e);
            });
    }

    private async loadUi() {
        const sensor = await this.service.getOneSensor(this.sensorId!);
        this.loadMap(sensor.configuration);
        this.ui.lastUpdateTime = sensor.lastActivityDate.toLocaleString('fr-FR');
    }

    private loadMap(conf: SensorConfigurationModel) {
        this.map.clear();

        this.map.set('Niveau de détection',
            {value: conf.detectLevel, updateFunction: newValue => this.updateParameters({detectLevel: newValue})});

        this.map.set('Niveau de NON détection',
            {value: conf.noDetectLevel, updateFunction: newValue => this.updateParameters({noDetectLevel: newValue})});

        this.map.set('Gain de filtrage',
            {value: conf.filterGain, updateFunction: newValue => this.updateParameters({filterGain: newValue})});

        this.map.set('Période de mesure',
            {value: conf.measurePeriod, updateFunction: newValue => this.updateParameters({measurePeriod: newValue})});

        this.map.set('Niveau stable',
            {value: conf.stableLevel, updateFunction: newValue => this.updateParameters({stableLevel: newValue})});

        this.map.set('Detection pente',
            {value: conf.slopeDetect, updateFunction: newValue => this.updateParameters({slopeDetect: newValue})});

        this.map.set('Timeout de confirmation',
            {
                value: conf.confirmTimeout,
                updateFunction: newValue => this.updateParameters({confirmTimeout: newValue})
            });

        this.map.set('Temps off (veille)',
            {value: conf.lowPowerTOff, updateFunction: newValue => this.updateParameters({lowPowerTOff: newValue})});
    }

    private async refreshParameters(): Promise<void> {
        this.ui.disableRefreshButton = true;
        try {
            await this.loadUi();
        } catch (e) {
            console.error(e);
            if (e instanceof Error)
                this.showError(e.message);
        } finally {
            this.ui.disableRefreshButton = false;
        }

    }

    private async updateParameters(request: SaveSensorConfigurationRequestModel) {
        try {
            await this.service.saveSensorConfiguration(this.sensorId!, request);
        } catch (e) {
            console.error(`Unable to update ${JSON.stringify(request)}.`, e);
            if (e instanceof Error)
                this.showError(e.message);
        }
    }

    private showError(errorMsg: string): void {
        const dialog = this.ui.errorDialog;
        dialog.message = errorMsg;
        dialog.show = true;
    }

}

interface Item {
    value: number,
    updateFunction: (value: number) => {};
}
