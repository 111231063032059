import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableLabelComponent = _resolveComponent("EditableLabelComponent")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    "items-per-page": _ctx.itemsPerPage,
    "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
    headers: _ctx.headers,
    items: _ctx.sensors,
    "item-value": "id",
    class: "elevation-5"
  }, {
    "item.name": _withCtx(({ item }) => [
      _createVNode(_component_EditableLabelComponent, {
        class: "clickable",
        value: item.columns.name,
        onValueUpdated: args => this.updateName(item.columns.id, args)
      }, null, 8, ["value", "onValueUpdated"])
    ]),
    "item.state": _withCtx(({ item }) => [
      _createVNode(_component_v_chip, {
        class: "ma-2",
        color: _ctx.getColor(item.columns.state),
        "text-color": "white"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.columns.state), 1)
        ]),
        _: 2
      }, 1032, ["color"])
    ]),
    "item.actions": _withCtx(({ item }) => [
      _createVNode(_component_v_icon, {
        size: "x-large",
        onClick: ($event: any) => (_ctx.showDetails(item.columns.id))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" mdi-magnify ")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["items-per-page", "headers", "items"]))
}