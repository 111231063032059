

import {Options, Vue} from "vue-class-component";

@Options({
    props: {
        value: String,
        type : String
    },
    emits: [
        'valueUpdated'
    ]
})
export default class EditableLabelComponent extends Vue {

    private fieldShown: boolean = false;
    private value?: string;
    private inputValue: string = '';


    mounted(): void {
        if (this.value !== undefined)
            this.inputValue = this.value;

    }

    private validate() {
        this.$emit('valueUpdated', this.inputValue);
        this.hideField();
    }

    private cancel() {
        this.hideField();
    }

    private showField(): void {
        this.fieldShown = true;
    }

    private hideField(): void {
        this.fieldShown = false;
    }

}
