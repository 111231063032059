


import {Options, Vue} from "vue-class-component";
import {SensorsService} from "@/services/SensorsService";
import {Context} from "@/Context";
import LastActivityMapper from "@/utils/LastActivityMapper";
import EditableLabelComponent from "@/components/common/EditableLabelComponent.vue";

@Options({
    components: {EditableLabelComponent}
})
export default class SensorsTableComponent extends Vue {

    private readonly service: SensorsService = Context.provideSensorService();

    private itemsPerPage: Number = 5;

    private sensors: SensorUi[] = [];

    private intervalId?: number;

    private headers = [

        {
            title: 'Nom',
            align: 'start',
            sortable: false,
            key: 'name',
        },
        {
            title: 'Capteur',
            align: 'start',
            sortable: false,
            key: 'id',
        },
        {title: 'Présence voiture', align: 'center', key: 'state'},
        {title: 'Firmware', align: 'center', key: 'firmwareName'},
        {title: 'Version', align: 'center', key: 'firmwareVersion'},
        {title: 'Dernière activité', align: 'start', key: 'lastActivity'},
        {title: '', align: 'end', key: 'actions'}
    ];

    async mounted(): Promise<void> {
        await this.refreshSensorList();

        this.intervalId = setInterval(async () => {
            await this.refreshSensorList();
        }, 1000);
    }

    private async refreshSensorList() {
        for (const sensor of (await this.service.getSensors())) {
            const uiSensor = this.getUiSensors(sensor.sn);

            if (uiSensor !== null) {
                uiSensor.name = sensor.name;
                uiSensor.state = sensor.lastCarDetectState ? 'Détectée' : '-';
                uiSensor.lastActivity = new LastActivityMapper(new Date()).getPrettyString(sensor.lastActivityDate);
                uiSensor.firmwareName = sensor.appName;
                uiSensor.firmwareVersion = sensor.appVersion;
            } else {
                this.sensors.push({
                    name: sensor.name,
                    id: sensor.sn,
                    state: sensor.lastCarDetectState ? 'Détectée' : '-',
                    lastActivity: new LastActivityMapper(new Date()).getPrettyString(sensor.lastActivityDate),
                    firmwareName: sensor.appName,
                    firmwareVersion: sensor.appVersion
                });
            }
        }
    }

    private getUiSensors(sn: string): SensorUi | null {
        const sensorsFound = this.sensors.filter(value => value.id === sn);

        if (sensorsFound.length < 1)
            return null;
        else return sensorsFound[0];
    }

    private getColor(state: string): string {
        if (state === 'Détectée')
            return 'green';
        return 'grey';
    }

    private showDetails(id: string) {
        this.$router.push(`/sensor/${id}`);
    }

    private async updateName(sensorId: string, name: string) {
        try {
            await this.service.setName(sensorId, name);
            await this.refreshSensorList();
        } catch (e) {
            console.error(`Unable to set name '${name}' to sensor '${sensorId}'.`, e);
        }
    }

    unmounted(): void {
        if (this.intervalId !== undefined)
            clearInterval(this.intervalId);
    }


}

interface SensorUi {
    name: String,
    id: String,
    state: string,
    lastActivity: string,
    firmwareName: string,
    firmwareVersion: string
}
