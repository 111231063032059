

import SensorsTableComponent from "@/components/SensorsTableComponent.vue";
import BigHeaderComponent from "@/components/BigHeaderComponent.vue";
import NavigationComponent from "@/components/NavigationComponent.vue";
import {Options, Vue} from "vue-class-component";
import IndicatorComponent from "@/components/IndicatorComponent.vue";

@Options({
    components: {
        IndicatorComponent,
        SensorsTableComponent,
        BigHeaderComponent,
        NavigationComponent
    }
})
export default class App extends Vue {
}
