
import {Options, Vue} from 'vue-class-component';

const IMG_BASE_URL = '/img/';
@Options({
    props: {
        title: String,
        value: String,
        type: String
    }
})
export default class IndicatorComponent extends Vue {

    private type?: string
    private logoUrl: string = IMG_BASE_URL + 'unknown.png';


    mounted(): void {
        switch (this.type) {
            case 'device': {
                this.logoUrl = IMG_BASE_URL + 'device.png';
                break;
            }
            case 'date': {
                this.logoUrl = IMG_BASE_URL + 'date.png';
                break;
            }
            default: {
                this.logoUrl = IMG_BASE_URL + 'unknown.png';
            }
        }
    }
}
