import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_SensorHeaderComponent = _resolveComponent("SensorHeaderComponent")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_SensorDetailsComponent = _resolveComponent("SensorDetailsComponent")!
  const _component_SensorLiveDataChartComponent = _resolveComponent("SensorLiveDataChartComponent")!
  const _component_SensorHistoryComponent = _resolveComponent("SensorHistoryComponent")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "elevation-2 overflow-y-auto",
    height: "100vh"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
            density: "comfortable",
            color: "blue-grey-darken-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "x-large" }, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-close ")
                ]),
                _: 1
              }),
              _createTextVNode(" Fermer ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_title, { class: "ma-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_SensorHeaderComponent, {
            class: "header",
            "sensor-id": this.$route.params.id
          }, null, 8, ["sensor-id"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_SensorDetailsComponent, {
            "sensor-id": this.$route.params.id
          }, null, 8, ["sensor-id"]),
          _createVNode(_component_SensorLiveDataChartComponent, {
            "sensor-id": this.$route.params.id
          }, null, 8, ["sensor-id"]),
          _createVNode(_component_SensorHistoryComponent, {
            "sensor-id": this.$route.params.id
          }, null, 8, ["sensor-id"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}