
import {Options, Vue} from 'vue-class-component';
import {SensorsService} from "@/services/SensorsService";
import {Context} from "@/Context";
import LastActivityMapper from "@/utils/LastActivityMapper";
import WarningDialogComponent from "@/components/WarningDialogComponent.vue";

@Options({
    components: {WarningDialogComponent},
    props: {
        sensorId: String
    }
})
export default class SensorComponent extends Vue {
    private readonly service: SensorsService = Context.provideSensorService();

    private sensorId?: string;
    private ui = {
        name: '',
        activity: '',
        firmwareName: '',
        firmwareVersion: '',
        warningIsShowing: false,
        calibrationIsAvailable : true
    };
    private intervalId?: number;


    mounted(): void {
        if (this.sensorId !== undefined) {
            this.loadUi();

            this.intervalId = setInterval(() => {
                this.loadUi();
            }, 5000)
        }
    }

    private async loadUi() {
        const sensor = await this.service.getOneSensor(this.sensorId!);

        this.ui.name = sensor.name;
        this.ui.activity = new LastActivityMapper(new Date()).getPrettyString(sensor.lastActivityDate);
        this.ui.firmwareName = sensor.appName;
        this.ui.firmwareVersion = sensor.appVersion;
    }

    private async startCalibration() {
        this.ui.warningIsShowing = false;
        this.ui.calibrationIsAvailable = false;

        await this.service.calibrate(this.sensorId!)

        setTimeout(()=>{
            this.ui.calibrationIsAvailable = true;
        }, 10000);
    }

    unmounted(): void {
        if (this.intervalId !== undefined)
            clearInterval(this.intervalId);
    }

}
