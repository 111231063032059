import {HttpAssetsBuilder} from "./HttpAssetsBuilder";
import {HttpAssets} from "../HttpAssets";

export class HttpAssetsBuilderImpl implements HttpAssetsBuilder {

    private jwt: string;


    private constructor() {
        this.jwt = "";
    }

    static create(): HttpAssetsBuilder {
        return new HttpAssetsBuilderImpl();
    }

    setJwt(jwt: string): HttpAssetsBuilder {
        this.jwt = jwt;
        return this;
    }

    build(): HttpAssets {
        const headers = new Headers();
        headers.set("token", this.jwt);

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('token', this.jwt);

        return new HttpAssets(headers, urlSearchParams);
    }

}