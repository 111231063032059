import {SensorsService} from "@/services/SensorsService";
import SensorsServiceImpl from "@/services/SensorsServiceImpl";
import {HttpAssetsBuilderImpl} from "@/services/assets/builders/HttpAssetsBuilderImpl";
import {ValidityAppMiddleware} from "@/services/middlewares/ValidityAppMiddleware";
import {SecurityAppMiddleware} from "@/services/middlewares/SecurityAppMiddleware";
import {Router} from "vue-router";
import Session from "@/instances/Session";
import {HttpAssets} from "@/services/assets/HttpAssets";
import TokenServiceImpl from "@/services/TokenServiceImpl";
import {TokenService} from "@/services/TokenService";
import getEnv from "@/utils/Environment";
import {TokenServiceDemoImpl} from "@/services/TokenServiceDemoImpl";
import {SensorsServiceDemoImpl} from "@/services/SensorsServiceDemoImpl";


export class Context {
    private static router?: Router;
    private static apiUrl? : string = getEnv("VUE_APP_API_URL");

    public static provideSensorService() : SensorsService {
        return new SensorsServiceDemoImpl();
    }

    public static provideTokenService() : TokenService {
        return new TokenServiceDemoImpl();
    }

    public static setRouter(router: Router) {
        this.router = router;
    }

    public static provideSecurityAppMiddleware(): SecurityAppMiddleware {
        return new SecurityAppMiddleware(this.router!);
    }

    public static provideValidityAppMiddleware(): ValidityAppMiddleware {
        return new ValidityAppMiddleware(this.provideTokenService(), this.router!);
    }

    private static createHttpAssets(): HttpAssets {
        const builder = HttpAssetsBuilderImpl.create();
        if (!Session.hasToken()) console.warn(new Error(`No token in session !`));
        else builder.setJwt(Session.getToken());
        return builder.build();
    }

}