import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {Context} from "@/Context";
import HomeView from "@/views/HomeView.vue";
import SensorView from "@/views/SensorView.vue";
import LoginView from "@/views/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            security: true
        }
    },
    {
        path: "/sensor/:id",
        name: "sensor",
        component: SensorView,
        meta: {
            security: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginView
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

Context.setRouter(router);

const securityMiddleware = Context.provideSecurityAppMiddleware();
const validityAppMiddleware = Context.provideValidityAppMiddleware();

router.beforeEach((to, from) => {
    if (typeof to.meta.security === 'boolean' && to.meta.security) {
        securityMiddleware.load().then().catch(reason => {
            console.error('Unable to execute security middle ware.', reason);
        });
        validityAppMiddleware.load().then().catch(reason => {
            console.error('Unable to execute validity middle ware.', reason);
        });
    }
});


export default router;
