

import {Options, Vue} from 'vue-class-component';
import IndicatorComponent from "@/components/IndicatorComponent.vue";
import SensorsTableComponent from "@/components/SensorsTableComponent.vue";
import BigHeaderComponent from "@/components/BigHeaderComponent.vue";

@Options({
    components: {BigHeaderComponent, SensorsTableComponent, IndicatorComponent}
})
export default class HomeView extends Vue {

}
