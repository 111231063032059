export default class LastActivityMapper {
    private readonly date: Date;


    constructor(date: Date) {
        this.date = date;
    }

    public getPrettyString(lastActivity: Date): string {
        let res: string;
        const diff = this.date.getTime() - lastActivity.getTime();

        if (diff >= this.days(1))
            res = 'Il y a ' + this.generateQuantityWord('jour', this.getDays(diff));
        else if (diff >= this.hours(1))
            res = 'Il y a ' + this.generateQuantityWord('heure', this.getHours(diff));
        else if (diff >= this.minutes(1))
            res = 'Il y a ' + this.generateQuantityWord('minute', this.getMinutes(diff));
        else
            res = 'Maintenant'

        return res;

    }

    private generateQuantityWord(word: string, value: number) {
        const prefix = value + ' ' + word;
        if (value > 1)
            return prefix + 's';
        else
            return prefix;
    }

    private days(count: number) {
        return Math.abs(this.hours(24) * count);
    }

    private hours(count: number) {
        return Math.abs(this.minutes(60) * count);
    }

    private minutes(count: number) {
        return Math.abs(1000 * 60 * count);
    }

    private getDays(ms: number) {
        return Math.round(ms / this.days(1));
    }

    private getHours(ms: number) {
        return Math.round(ms / this.hours(1));
    }

    private getMinutes(ms: number) {
        return Math.round(ms / this.minutes(1));
    }
}