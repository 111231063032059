
import {Options, Vue} from 'vue-class-component';

@Options({
    props: {
        showing: Boolean,
        title: String,
        message: String
    },
    emits: ['onCancel', 'onConfirm']
})
export default class WarningDialogComponent extends Vue {



}
